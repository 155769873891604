import { FaLinkedinIn } from "@react-icons/all-files/fa/FaLinkedinIn"
import React from "react"
import "./footer.scss"

const Footer = () => {
  const today = new Date()
  const year = today.getFullYear()

  return (
    <footer className="pageFooter">
      <div className="content">
        <div className="contact">
          <h2>Yhteystiedot</h2>
          <div className="person">
            <span className="name">Jori Kuttila</span>
            <a href="mailto:jori.kuttila@salesimpact.fi" target="_blank">
              jori.kuttila@salesimpact.fi
            </a>
            <a href="https://www.salesimpact.fi" target="_blank">
              www.salesimpact.fi
            </a>
            <a href="tel:+35840022943">Puh. 0400 229 436</a>
            <div className="some">
              <a
                href="https://www.linkedin.com/in/jori-kuttila-1039a941/"
                target="_blank"
                title="Jorin LinkedIn"
              >
                <FaLinkedinIn size={25} color="#fff" />
              </a>
            </div>
          </div>
          <div className="person">
            <span className="name">Markku Hugg</span>
            <a href="mailto:markku.hugg@gmail.com" target="_blank">
              markku.hugg@gmail.com
            </a>
            <a href="tel:+358400258410">Puh. 0400 258 410</a>
            <div className="some">
              <a
                href="https://www.linkedin.com/in/markkuhugg/"
                target="_blank"
                title="Markun LinkedIn"
              >
                <FaLinkedinIn size={25} color="#fff" />
              </a>
            </div>
          </div>
        </div>
        <div className="disclaimer">
          <h2>Disclaimer</h2>
          <p>
            Kirjoittajien mielipiteet edustavat henkilökohtaisia näkemyksiä ja
            ajatuksia.
          </p>
        </div>
        <div className="copy">© {year}</div>
      </div>
    </footer>
  )
}

export default Footer
